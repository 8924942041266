import * as React from 'react';
import tw, { styled } from 'twin.macro';

import Layout from '../layouts/layout';
import PageImage from '../images/no-page-found.svg';
import MetaTags from '../components/SiteMeta';

const ImageWrapper = styled.div`
  ${tw`flex m-auto`}

  svg {
    max-width: 100%;
  }
`;

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <MetaTags title="Page not found" description="Page not found" />
      <main>
        <div className="ves-section">
          <div className="ves-container flex justify-center items-center py-5">
            <ImageWrapper>
              <PageImage />
            </ImageWrapper>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default NotFoundPage;
